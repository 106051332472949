import { Controller } from "@hotwired/stimulus"

export default class GeneratorJobsCompletionController extends Controller {
  static targets = ['finalPriceWorkPartsDisplay', 'finalPriceCourtesyCarDisplay', 'finalPriceDisplay', 'finalPriceVatDisplay', 'finalPriceWithVatDisplay', 'finalPriceWorkPartsContainer', 'finalPriceWorkParts', 'finalPricePerDayCourtesyCar', 'finalPriceNumberOfDaysCourtesyCar', 'finalPriceCourtesyCar', 'commentContainer', 'commentField', 'commentRequiredWarning', 'submitPricesButton', 'confirmPricesButton', 'priceHigherThanAcceptedPriceWarning']

  connect() {
    this.vatFactor = Number(this.data.get('vat-factor'));
    this.originalFinalWorkPartsPrice = Number(this.data.get('original-final-work-parts-price')).toFixed(2);
    this.serviceFee = Number(this.data.get('service-fee')).toFixed(2);
    this.priceChanged();
  }

  finalPriceWorkPartsChanged() {
    this.priceChanged();
  }

  finalPriceNumberOfDaysCourtesyCarChanged() {
    this.priceChanged();
  }

  finalPricePerDayCourtesyCarChanged() {
    this.priceChanged();
  }

  editWorkPartsPrice() {
    this.finalPriceWorkPartsContainerTarget.classList.remove('hidden');
  }

  toggleCommentField() {
    this.commentContainerTarget.classList.toggle('hidden');
  }

  handleCustomerWontReturn() {
    document.querySelector('.close-complete-modal-button').click();
    document.querySelector('.car-owner-did-not-show-modal-button').click();
  }

  confirmPrices() {
    this.confirmPricesButtonTarget.classList.add('hidden');
    this.submitPricesButtonTarget.classList.remove('hidden');
  }

  priceChanged() {
    this.finalPriceWorkParts = Number(this.finalPriceWorkPartsTarget.value);
    this.finalPriceCourtesyCar = Number(this.finalPricePerDayCourtesyCarTarget.value) * Number(this.finalPriceNumberOfDaysCourtesyCarTarget.value);

    this.finalPrice = Number(this.finalPriceWorkParts) + Number(this.finalPriceCourtesyCar) + Number(this.serviceFee);
    this.finalPriceVat = this.finalPrice * this.vatFactor;
    this.finalPriceWithVat = this.finalPrice + this.finalPriceVat;

    this.finalPriceCourtesyCarTarget.value = this.finalPriceCourtesyCar.toFixed(2);

    this.finalPriceWorkPartsDisplayTarget.innerHTML = this.finalPriceWorkParts.toFixed(2);

    if (this.finalPriceWorkParts != this.originalFinalWorkPartsPrice) {
      this.finalPriceWorkPartsDisplayTarget.classList.add('text-red-600')
    } else {
      this.finalPriceWorkPartsDisplayTarget.classList.remove('text-red-600')
    }

    if (this.finalPriceWorkParts > this.originalFinalWorkPartsPrice) {
      this.priceHigherThanAcceptedPriceWarningTarget.classList.remove('hidden');
    } else {
      this.priceHigherThanAcceptedPriceWarningTarget.classList.add('hidden');
    }

    if (this.finalPriceWorkParts < this.originalFinalWorkPartsPrice) {
      this.commentContainerTarget.classList.remove('hidden');
      this.commentRequiredWarningTarget.classList.remove('hidden');
      this.commentFieldTarget.required = true;

    } else {
      if (this.commentFieldTarget.value == "")
        this.commentContainerTarget.classList.add('hidden');

      this.commentFieldTarget.required = false;        
      this.commentRequiredWarningTarget.classList.add('hidden');
    }
  
    this.finalPriceCourtesyCarDisplayTarget.innerHTML = this.finalPriceCourtesyCar.toFixed(2);
    this.finalPriceDisplayTarget.innerHTML = this.finalPrice.toFixed(2);
    this.finalPriceVatDisplayTarget.innerHTML = this.finalPriceVat.toFixed(2);
    this.finalPriceWithVatDisplayTarget.innerHTML = this.finalPriceWithVat.toFixed(2);
  }
}
