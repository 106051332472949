import { Controller } from "@hotwired/stimulus";

export default class GeneratorJobsTemplateController extends Controller {
  static targets = ['templateSelector', 'signatureArea', 'dropdown', 'filterInput'];

  selectedIndex = -1;

  connect() {
    this.templateSelectorTargets.forEach((target) => {
      target.addEventListener("click", () => {
        this.updateTextArea(target);
      });
    });

    document.addEventListener("keydown", this.handleEscapeKey.bind(this));
    document.addEventListener("click", this.handleClickOutside.bind(this));
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  updateTextArea(selectedOption) {
    const signature = selectedOption.getAttribute("data-signature");
    const title = selectedOption.getAttribute("data-title");

    this.signatureAreaTarget.value = signature;

    this.dropdownTarget.classList.add('hidden');
    this.filterInputTarget.setAttribute("placeholder", title);
  }

  filterTemplates() {
    const filter = this.filterInputTarget.value.toUpperCase();

    this.templateSelectorTargets.forEach((template) => {
      const textValue = template.textContent || template.innerText;

      if (textValue.toUpperCase().indexOf(filter) > -1) {
        template.style.display = "";
      } else {
        template.style.display = "none";
      }
    });
  }

  handleEscapeKey(event) {
    if (event.key === "Escape") {
      this.dropdownTarget.classList.add('hidden');
      this.filterInputTarget.blur();
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target) && !this.dropdownTarget.contains(event.target) && !this.signatureAreaTarget.contains(event.target)) {
      this.dropdownTarget.classList.add('hidden');
      this.filterInputTarget.blur();
    }
  }

  handleKeyDown(event) {
    const key = event.key;
    if (key === "ArrowUp") {
      event.preventDefault();
      this.navigateOptions(-1);
    } else if (key === "ArrowDown") {
      event.preventDefault();
      this.navigateOptions(1);
    }
  }

  navigateOptions(direction) {
    const optionCount = this.templateSelectorTargets.length;

    if (optionCount === 0) return;

    this.selectedIndex = (this.selectedIndex + direction + optionCount) % optionCount;

    this.templateSelectorTargets.forEach((target, index) => {
      if (index === this.selectedIndex) {
        target.classList.add("bg-blue-200");
        target.focus();
      } else {
        target.classList.remove("bg-blue-200");
      }
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        this.selectOption(this.selectedIndex);
      }
    });
  }

  selectOption(index) {
    const selectedOption = this.templateSelectorTargets[index];
    if (selectedOption) {
      this.updateTextArea(selectedOption);
      this.dropdownTarget.classList.add('hidden');
      this.filterInputTarget.blur();
    }
  }
}
