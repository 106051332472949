import { Controller } from "@hotwired/stimulus";
import Highcharts from 'highcharts';

export default class extends Controller {
  connect() {
    const chartName = this.data.get("chartName");
    const chartHeaderName = this.data.get("chartHeaderName");
    const chartData = JSON.parse(this.data.get("chartData"));
    const tooltipData = JSON.parse(this.data.get("tooltipData"));
    const startTime = parseInt(this.data.get("startTime"), 10);
    const pointInterval = parseInt(this.data.get("pointInterval"), 10);

    function shortenValue(value) {
      if (value >= 1000) {
        const shortened = (value / 1000).toFixed(1);
        return Number.isInteger(parseFloat(shortened)) ? parseInt(shortened) + 'K' : shortened + 'K';
      }
      return value;
    }

    new Highcharts.Chart({
      chart: {
        type: 'column',
        renderTo: 'chart-container-' + chartName,
        height: 180
      },
      credits: {
        enabled: false
      },
      title: { text: '' },
      xAxis: { type: 'datetime' },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: function() {
            return shortenValue(this.value);
          }
        },
        visible: true
      },
      plotOptions: {
        series: {
          pointStart: startTime,
          pointInterval: pointInterval,
          color: '#fcb034'
        }
      },
      series: [{
        showInLegend: false,
        data: chartData
      }],
      tooltip: {
        shared: true,
        backgroundColor: 'rgba(34, 34, 34, 0.9)',
        borderColor: 'rgba(34, 34, 34, 0.9)',
        style: {
          color: '#fff',
          padding: '.1em',
          margin: '.1em',
        },
        borderRadius: 2,
        useHTML: true,
        formatter: function() {
          const index = this.points[0].point.index;
          const date = tooltipData[index][0];
          return `
            <div class="font-bold">${date}</div>
            <div class="font-light">${chartHeaderName}: ${this.y}</div>
          `;
        }
      }
    });
  }
}