import { Controller } from "@hotwired/stimulus"

export default class JobsSchedulingDinitrolController extends Controller {
  static targets = ['jobWillBeCompletedOn', 'completionTimeOfDay', 'carPickupAt', 'pickupTimeOfDay', 'formattedTo', 'confirmDateButton', 'submitDateButton']

  connect() {
    this.jobWillBeCompletedOnTarget.addEventListener('change', () => {
      const selectedOption = this.jobWillBeCompletedOnTarget.options[this.jobWillBeCompletedOnTarget.selectedIndex];

      if (selectedOption.dataset.jobWillBeCompletedOn) {
        this.completionTimeOfDayTarget.value = selectedOption.dataset.completionTimeOfDay;

        this.carPickupAtTarget.value = selectedOption.dataset.carPickupAt;
        this.pickupTimeOfDayTarget.value = selectedOption.dataset.pickupTimeOfDay;

        this.formattedToTarget.innerText = selectedOption.dataset.formattedTo || '';
      }
    });
  }

  confirmTime() {
    const hasJobCompletionDate = Boolean(this.jobWillBeCompletedOnTarget.value);

    if (hasJobCompletionDate) 
      this.toggleConfirmDateButton(true);
  }

  toggleConfirmDateButton(hasValue) {
    const action = hasValue ? 'add' : 'remove';
    this.confirmDateButtonTarget.classList[action]('hidden');
    this.submitDateButtonTarget.classList[hasValue ? 'remove' : 'add']('hidden');
  }
}
