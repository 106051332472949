import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modalTrigger"];

  connect() {
    const modalContainer = this.modalTriggerTarget.closest('[data-modal-target="container"]');

    if (this.data.get("display") === "true" && (!modalContainer || modalContainer.classList.contains("hidden"))) {
      setTimeout(() => {
        this.modalTriggerTarget.click();
      }, 1000);
    }
  }
}
