import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

export default class GeneratorNextAvailableTimeMainController extends Controller {
  static targets = ['dateAfterVacationDisplay', 'closedTo']

  connect() {
    this.availabilityConfig = JSON.parse(this.data.get('availabilityConfig'));
    this.locale = document.querySelector('meta[name="locale"]').getAttribute('content');
    this.vacationChanged();
  }

  vacationChanged() {
    if (!this.element) return;

    const closedFrom = this.element['mechanic_availability_config[closed_from]'].value || this.availabilityConfig.closed_from;
    const closedTo = this.element['mechanic_availability_config[closed_to]'].value || this.availabilityConfig.closed_to;
    const vacationNextFreeTimeDaysForward = this.element['mechanic_availability_config[vacation_next_free_time_days_forward]'].value || this.availabilityConfig.vacation_next_free_time_days_forward;

    const increment = vacationNextFreeTimeDaysForward || this.availabilityConfig.next_free_time_days_forward;

    if (!closedFrom || closedFrom.length == 0 || !closedTo || closedTo.length == 0 || !vacationNextFreeTimeDaysForward) return;

    let nextDate;
    if (closedTo) {
      nextDate = this.buildNextDate(moment(closedTo), increment);
      this.closedToTarget.innerHTML = moment(closedTo).locale(this.locale).format('dddd, LL');
    } else {
      nextDate = this.buildNextDate(moment(), increment);
    }
    this.dateAfterVacationDisplayTargets.forEach(elm => {
      elm.innerHTML = nextDate.locale(this.locale).format('dddd, LL');
    });
  }

  buildNextDate(initialDay, increment) {
    const excludedDates = this.availabilityConfig.non_business_days || [];
    let incrementedWorkdays = 0;
    let nextDate = initialDay;

    while (incrementedWorkdays < increment) {
      nextDate = nextDate.add(1, 'days');

      if (!excludedDates.includes(nextDate.format('YYYY-MM-DD'))) {
        incrementedWorkdays++;
      }
    }
    return nextDate;
  }

  submit(event) {
    const modal = this.element.closest('[data-modal-target="container"]')
    const closeButton = modal.querySelector('[data-action="click->modal#close"]')
    closeButton && closeButton.click()
  }
}
