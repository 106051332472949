import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['messagesTab'];

  connect() {
    this.unreadMessagesOnOfferCount = this.element.getAttribute("unread-messages-on-offer-count");
    this.jobId = this.element.getAttribute("job-id");
    this.offerId = this.element.getAttribute("offer-id");
    this.adminPresent = this.element.getAttribute("admin-present");

    if (this.unreadMessagesOnOfferCount > 0 && this.adminPresent == "false") {
      // Mark as read when starting on the messages tab
      if (this.isMessagesTabActive()) {
        this.markAsRead();
      }

      // Mark as read when clicking on the messages tab
      this.messagesTabTarget.addEventListener('click', () => {
        if (this.isMessagesTabActive()) {
          this.markAsRead();
        }
      });
    }
  }

  isMessagesTabActive() {
    return this.messagesTabTarget.classList.contains('active');
  }

  markAsRead() {
    const unreadMessagesTabIcon = document.querySelector('.unread-comment-icon-messages-tab')
    const unreadMessagesMenuIcons = document.querySelectorAll('.unread-messages-menu-icon')

    if (unreadMessagesTabIcon && !unreadMessagesTabIcon.classList.contains('hidden')) {
      this.updateMenuIcons(unreadMessagesMenuIcons);
      this.sendMarkAsReadRequest();
    }

    if (unreadMessagesTabIcon) {
      unreadMessagesTabIcon.classList.add('hidden');
    }
  }

  updateMenuIcons(icons) {
    icons.forEach((icon) => {
      let newUnreadMessagesCount = icon.innerHTML - this.unreadMessagesOnOfferCount;
      if (newUnreadMessagesCount < 1) {
        icon.parentNode.classList.add('hidden');
      } else {
        icon.innerHTML = newUnreadMessagesCount;
      }
    })
  }

  sendMarkAsReadRequest() {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    const url = `/jobs/${this.jobId}/mark_viewed_at?offer_id=${this.offerId}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
        'X-CSRF-Token': token
      }
    })
  }
}
