import { Controller } from "@hotwired/stimulus"
import deepmerge from "deepmerge"
import FormDataObjectizer from "form-data-objectizer"
import qs from "qs"

// Overrides form submit and merges inputs in the form into the URL using a redirect
export default class MergeWithParamsController extends Controller {
  connect() {
    this.element.addEventListener("submit", (...args) => this.submit(...args))
  }

  submit(e) {
    e.preventDefault()

    const form = this.element
    const formData = new FormData(form)
    const parametersFromForm = FormDataObjectizer.toObject(formData)
    const parametersFromUrl = qs.parse(location.search.substr(1))
    const mergedParameters = deepmerge(parametersFromUrl, parametersFromForm)
    const newPath = `${location.pathname}?${qs.stringify(mergedParameters)}`

    location.href = newPath
  }
}
