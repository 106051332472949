import { Controller } from "@hotwired/stimulus";

export default class ImageUploadController extends Controller {
  static targets = ['placeholderImage', 'currentImage', 'newImage', 'deleteCurrentImageBtn', 'saveNewImageBtn'];

  connect() {}

  loadImage(event){
    if (this.hasDeleteCurrentImageBtnTarget) {
      this.deleteCurrentImageBtnTarget.remove();
      this.saveNewImageBtnTarget.classList.toggle("hidden");
    }

    const newImageElm = this.newImageTarget;

    newImageElm.src = URL.createObjectURL(event.target.files[0]);

    newImageElm.onload = () => { URL.revokeObjectURL(newImageElm.src); }

    this.currentImageTarget.classList.add('hidden');
  }
}
