import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['disableToggle', 'formField'];

  connect() {
    this.toggleFormFieldsDisabled()
  }

  toggleFormFieldsDisabled(){
    this.formFieldTargets.forEach((formField) => {
      formField.disabled = !this.disableToggleTarget.checked
    })
  }
}
