import { Controller } from "@hotwired/stimulus"
import { currencyFormatter } from './currency_formatter.js';

export default class GeneratorJobsOfferController extends Controller {
  static targets = ['editable', 'workPartsPriceContainer', 'courtesyCarPriceContainer', 'courtesyCarRow', 'totalExVatPriceContainer', 'vatPriceContainer', 'totalWithVatPriceContainer', 'addWorkLineItem', 'addCourtesyCarLineItem']

  connect() {
    this.vatFactor = Number(this.data.get('vat-factor'));
    this.hourlyRate = Number(this.data.get('hourly-rate'));
    this.courtesyCar = this.data.get('courtesy-car');
    this.priceOfCourtesyCar = Number(this.data.get('price-of-courtesy-car'));
    this.courtesyCarPriceFromBidSetting = Number(this.data.get('courtesy-car-price-from-bid-setting'));
    this.offerId = this.data.get('offer-id');
    this.units = JSON.parse(this.data.get('units'));
    this.timeDiscountPercent = Number(this.data.get('time-discount-percent'));
    this.productDiscountPercent = Number(this.data.get('product-discount-percent'));
    this.isAdmin = this.data.get('is-admin');
    this.currentCountryLocale = this.data.get('current-country-locale');
    this.underseal = this.data.get('underseal');

    if (this.courtesyCar === 'true' && this.priceOfCourtesyCar > 0) {
      this.addCourtesyCarToOffer(false);
    }

    if(!this.offerId) {
      this.toggleEdit();

      if (this.underseal == "false") {
        this.addLineItem();
      }
    }
  }

  toggleEdit() {
    if (this.isAdmin == "true" || !this.offerId) {
      this.editableTargets.forEach(elm => {
        elm.classList.toggle('hidden');
      });
    } else {
      const modal = document.getElementById('cant-edit-offer-modal');
      const modalController = this.application.getControllerForElementAndIdentifier(modal, 'modal');
      modalController.open();
    }
  }

  addLineItem() {
    const container = document.getElementById('line-items-container');

    const lineItemId = 'new-' + Math.floor(Math.random() * 1000000000);

    const lineItem = document.createElement('div');
    lineItem.classList.add('flex', 'gap-4', 'py-2', 'mb-3', 'border-b', 'border-gray', 'offer-line-item', 'text-xs', 'sm:text-sm');

    lineItem.dataset.lineItemId = lineItemId;

    const unitOptions =
      this.units.reduce((str, unit) => {
        return str + `<option value="${unit[1]}">${unit[0]}</option>`;
      }, '');

    lineItem.innerHTML = `
        <div class="w-48 sm:w-3/12">
          <input type="text" name="offer_line_items[${lineItemId}][description]" required value="" class="offer-line-item-description border border-gray-300 rounded w-full p-2" data-job-offer-target="editable">
        </div>
        <div class="w-16 sm:w-1/12">
          <input type="number" name="offer_line_items[${lineItemId}][quantity]" step="any" value="1" class="border border-gray-300 rounded w-full p-2" data-job-offer-target="editable" data-action="job-offer#lineItemChanged">
        </div>
        <div class="w-20 sm:w-2/12">
          <select name="offer_line_items[${lineItemId}][unit]" class="border border-gray-300 rounded w-full p-2" data-job-offer-target="editable">
            ${unitOptions}
          </select>
        </div>
        <div class="w-20 sm:w-1/12">
          <input type="number" name="offer_line_items[${lineItemId}][customer_price]" step="0.01" required value="0" class="offer-line-item-price border border-gray-300 rounded w-full p-2" data-job-offer-target="editable" data-action="job-offer#lineItemChanged">
        </div>
        <div class="w-24 sm:w-24">
          <div class="flex" data-job-offer-target="editable">
            <input type="number" name="offer_line_items[${lineItemId}][discount_percent]" class="border-l border-t border-b border-gray-300 rounded-l p-2 w-full" data-action="job-offer#lineItemChanged">
            <label role="button" for="priceOfCourtesyCar" class="bg-gray-200 border border-gray-300 rounded-r p-2 flex items-center text-xs">%</label>
          </div>
        </div>
        <div class="w-24 sm:w-2/12 flex justify-between sm:justify-end text-right">
          <span id="line-item-price-container-${lineItemId}" data-price=""></span>
          <i class="material-icons cursor-pointer ml-4" data-job-offer-target="editable" data-action="click->job-offer#removeLineItem">clear</i>
        </div>
    `;

    container.appendChild(lineItem);
  }

  addWorkLineItem() {
    const container = document.getElementById('line-items-container');

    const lineItemId = 'new-' + Math.floor(Math.random() * 1000000000);

    const lineItem = document.createElement('div');

    lineItem.classList.add('flex', 'gap-4', 'py-2', 'mb-3', 'border-b', 'border-gray', 'offer-line-item', 'text-sm');

    lineItem.dataset.lineItemId = lineItemId;
    lineItem.dataset.workLineItem = true;

    const unitOptions =
      this.units.reduce((str, unit) => {
        return str + `<option value="${unit[1]}" ${ unit[1] == 'hours' ? 'selected' : '' }>${unit[0]}</option>`;
      }, '');

    const price = (this.hourlyRate || 0);

    const priceFormatted = this.formatPrice(price);

    lineItem.innerHTML = `
        <div class="w-48 sm:w-3/12">
          <input type="text" name="offer_line_items[${lineItemId}][description]" required value="" class="border border-gray-300 rounded w-full p-2" data-job-offer-target="editable">
        </div>
        <div class="w-16 sm:w-1/12">
          <input type="number" name="offer_line_items[${lineItemId}][quantity]" step="any" value="1" class="border border-gray-300 rounded w-full p-2" data-job-offer-target="editable" data-action="job-offer#lineItemChanged">
        </div>
        <div class="w-20 sm:w-2/12">
          <select name="offer_line_items[${lineItemId}][unit]" class="border border-gray-300 rounded w-full p-2" data-job-offer-target="editable">
            ${unitOptions}
          </select>
        </div>
        <div class="w-20 sm:w-1/12">
          <input type="number" name="offer_line_items[${lineItemId}][customer_price]" step="0.01" value="${price}" class="border border-gray-300 rounded w-full p-2" data-job-offer-target="editable" data-action="job-offer#lineItemChanged">
        </div>
        <div class="w-24 sm:w-24">
          <div class="flex" data-job-offer-target="editable">
            <input type="number" name="offer_line_items[${lineItemId}][discount_percent]" value="${this.timeDiscountPercent}" class="border-l border-t border-b border-gray-300 rounded-l p-2 w-full" data-action="job-offer#lineItemChanged">
            <label role="button" for="priceOfCourtesyCar" class="bg-gray-200 border border-gray-300 rounded-r p-2 flex items-center text-xs">%</label>
          </div>
        </div>
        <div class="w-24 sm:w-2/12 flex justify-between sm:justify-end text-right">
          <span id="line-item-price-container-${lineItemId}" data-price="${price}">${priceFormatted}</span>
          <i class="material-icons cursor-pointer ml-4" data-job-offer-target="editable" data-action="click->job-offer#removeLineItem">clear</i>
        </div>
    `;

    container.appendChild(lineItem);

    this.addWorkLineItemTarget.classList.add('hidden');

    this.updateLineItemPrice(lineItem);
    this.updatePriceSummary();
  };

  addCourtesyCar() {
    this.courtesyCarRowTarget.classList.toggle('hidden');
    this.addCourtesyCarToOffer(true);
  }

  addCourtesyCarToOffer(editable) {
    const container = document.getElementById('line-items-container');

    const lineItemId = 'new-' + Math.floor(Math.random() * 1000000000);

    const lineItem = document.createElement('div');

    lineItem.classList.add('flex', 'gap-4', 'py-2', 'mb-3', 'border-b', 'border-gray', 'offer-line-item', 'text-sm');
    lineItem.dataset.lineItemId = lineItemId;
    lineItem.dataset.courtesyCar = true;

    const price = this.priceOfCourtesyCar || this.courtesyCarPriceFromBidSetting || '0';

    const priceFormatted = this.formatPrice(price);

    lineItem.innerHTML = `
      <div class="self-center w-48 sm:w-3/12">
        ${this.data.get('courtesy-car-headline')}
        <input type="hidden" name="offer_line_items[${lineItemId}][description]" value="${this.data.get('courtesy-car-headline')}">
        <input type="hidden" name="offer_line_items[${lineItemId}][courtesy_car]" value="1">
      </div>
      <div class="w-16 sm:w-1/12">
        <input type="number" name="offer_line_items[${lineItemId}][quantity]" step="1" value="1" class="border border-gray-300 rounded w-full p-2 ${editable ? '' : 'hidden'}" data-job-offer-target="editable" data-action="job-offer#lineItemChanged">
        <span class="${editable ? 'hidden' : ''}" data-job-offer-target="editable">1</span>
      </div>
      <div class="w-20 sm:w-2/12"></div>
      <div class="w-20 sm:w-1/12">
        <input type="number" name="offer_line_items[${lineItemId}][customer_price]" step="0.01" value="${price}" class="border border-gray-300 rounded w-full p-2 ${editable ? '' : 'hidden'}" data-job-offer-target="editable" data-action="job-offer#lineItemChanged">
        <span class="${editable ? 'hidden' : ''}" data-job-offer-target="editable">${priceFormatted}</span>
      </div>
      <div class="self-center w-24 sm:w-24">
        0%
      </div>
      <div class="flex justify-between sm:justify-end text-right w-24 sm:w-2/12">
        <span id="line-item-price-container-${lineItemId}" data-price="${price}">${priceFormatted}</span>
        <i class="material-icons cursor-pointer ml-4 ${editable ? '' : 'hidden'}" data-job-offer-target="editable" data-action="click->job-offer#removeLineItem">clear</i>
      </div>
    `;

    container.appendChild(lineItem);

    this.addCourtesyCarLineItemTarget.classList.add('hidden');

    this.updatePriceSummary();
  }

  removeLineItem(event) {
    event.preventDefault();

    const lineItem = event.target.closest('div.offer-line-item');

    lineItem.remove();

    if (lineItem.dataset.workLineItem === 'true') {
      this.addWorkLineItemTarget.classList.remove('hidden');
    }

    if (lineItem.dataset.courtesyCar === 'true') {
      this.courtesyCarRowTarget.classList.add('hidden');
      this.addCourtesyCarLineItemTarget.classList.remove('hidden');
    }

    this.updatePriceSummary();
  }

  lineItemChanged(event) {
    const lineItem = event.target.closest('div.offer-line-item');

    this.updateLineItemPrice(lineItem);
  }

  updateLineItemPrice(lineItem) {
    const lineItemId = lineItem.dataset.lineItemId;

    const priceContainer = document.getElementById(`line-item-price-container-${lineItemId}`);
    const quantity = lineItem.querySelector('[name$="[quantity]"]').value;
    const customerPrice = lineItem.querySelector('[name$="[customer_price]"]').value;
    const discountPercent = (lineItem.querySelector('[name$="[discount_percent]"]') || { value: 0 }).value;
    const price = quantity * customerPrice * (1 - discountPercent / 100);

    priceContainer.dataset.price = price;
    priceContainer.innerHTML = this.formatPrice(price);

    this.updatePriceSummary();
  }

  updatePriceSummary() {
    const lineItems = document.querySelectorAll('.offer-line-item');
    let workPartsPrice = 0;
    let courtesyCarPrice = 0;

    lineItems.forEach(lineItem => {
      const lineItemId = lineItem.dataset.lineItemId;
      const priceContainer = document.getElementById(`line-item-price-container-${lineItemId}`);

      const price = parseFloat(priceContainer.dataset.price);

      if (lineItem.dataset.courtesyCar === 'true') {
        courtesyCarPrice += price || 0;
      } else {
        workPartsPrice += price || 0;
      }
    });

    const totalExVatPrice = workPartsPrice + courtesyCarPrice;
    const vatPrice = totalExVatPrice * this.vatFactor;
    const totalWithVatPrice = totalExVatPrice + vatPrice;

    this.workPartsPriceContainerTarget.innerHTML = this.formatPrice(workPartsPrice);
    this.courtesyCarPriceContainerTarget.innerHTML = this.formatPrice(courtesyCarPrice);
    this.totalExVatPriceContainerTarget.innerHTML = this.formatPrice(totalExVatPrice);
    this.vatPriceContainerTarget.innerHTML = this.formatPrice(vatPrice);
    this.totalWithVatPriceContainerTarget.innerHTML = this.formatPrice(totalWithVatPrice);
  }

  formatPrice(value) {
    return currencyFormatter(this.currentCountryLocale, value);
  }
}
