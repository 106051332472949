import { Controller } from "@hotwired/stimulus"

export default class MultiFormSubmitController extends Controller {
  static targets = ['form'];

  connect() {
    this.formTargets.forEach((form) => {
      form.addEventListener('submit', this.handleSubmitWithFormData.bind(this))
    });
  }

  handleSubmitWithFormData(evt) {
    evt.preventDefault();

    const url = this.data.get('url');

    const formData = new FormData();

    this.formTargets.forEach((form) => {
      new FormData(form).forEach((value, key) => {
        formData.append(key, value);
      })
    })

    fetch(
      url,
      {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: formData,
        redirect: 'manual'
      })
        .then(response => {
          Turbo.clearCache();
          Turbo.visit(response.url);
        });
  }
}
