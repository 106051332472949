import { Controller } from "@hotwired/stimulus";
import { createPopper } from '@popperjs/core';

let popperInstance = null;

export default class extends Controller {
  static targets = ['content', 'trigger', 'background'];

  initialize() {
    if (!this.contentTarget.querySelector('div[data-popper-arrow]')) {
      let arrow = document.createElement('div');
      arrow.dataset.popperArrow = null;
      this.contentTarget.appendChild(arrow);
    }
  }

  create() {
    popperInstance = createPopper(this.triggerTarget, this.contentTarget, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]
    });
  }

  destroy() {
    if (popperInstance) {
      popperInstance.destroy();
      popperInstance = null;
    }
  }

  show() {
    this.contentTarget.setAttribute('data-show', '');

    //To make it work for pages with Bootstrap
    this.contentTarget.style.display = 'block';
    this.contentTarget.style.color = 'black';

    if (this.hasBackgroundTarget)
      this.backgroundTarget.style.display = 'block';

    this.create();
  }

  hide() {
    console.log('e');

    this.contentTarget.removeAttribute('data-show');

    //To make it work for pages with Bootstrap
    this.contentTarget.style.display = 'none';

    //To make it work for mobile (closing when clicking the BG)
    if (this.hasBackgroundTarget)
      this.backgroundTarget.style.display = 'none';

    this.destroy();
  }

  toggle() {
    if (!popperInstance) {
      this.show();
    } else {
      this.hide();
    }
  }
}
