import { Controller } from "@hotwired/stimulus"

export default class JobsBookTimeController extends Controller {
  static targets = ['jobWillBeCompletedOn', 'completionTimeOfDay', 'carPickupAt', 'pickupTimeOfDay', 'confirmDateButton', 'submitDateButton']

  connect() {
    this.hasBookingPreferences = this.data.get("has-booking-preferences") == "true";
    this.isAdmin = this.data.get("is-admin") == "true";
    this.rejectedDueToUnverifiedNoShow = this.data.get("rejected-due-to-unverified-no-show") == "true";

    this.setupEventListeners();

    // makes sure it's not possible pick a time before the completion time if same completion and pickup date
    if (this.jobWillBeCompletedOnTarget.value === this.carPickupAtTarget.value) {
      this.toggleTimeOptions();
    }

    // if car owner bookingpreferences exist then the mechanic is only allowed to schedule those
    this.checkAndClearCompletionOptions();
  }

  setupEventListeners() {
    this.jobWillBeCompletedOnTarget.addEventListener('change', () => {
      this.carPickupAtTarget.datepicker.setDate(this.jobWillBeCompletedOnTarget.value)
      this.carPickupAtTarget.datepicker.setOptions({
        minDate: this.jobWillBeCompletedOnTarget.value
      });
      this.synchronizeTimeOptions();
    });

    this.carPickupAtTarget.addEventListener('show', evt => {
      evt.detail.datepicker.setOptions({
        minDate: this.jobWillBeCompletedOnTarget.value
      });
    });

    this.carPickupAtTarget.addEventListener('change', () => {
      this.synchronizeTimeOptions();
    });

    this.completionTimeOfDayTarget.addEventListener('change', () => {
      this.synchronizeTimeOptions();
    });
  }

  synchronizeTimeOptions(selectedFromPreferences = false) {
    const pickupTimeSelect = this.pickupTimeOfDayTarget;
    const completionTimeValue = this.completionTimeOfDayTarget.value;

    if (this.jobWillBeCompletedOnTarget.value === this.carPickupAtTarget.value) {
      this.toggleTimeOptions();

      if (selectedFromPreferences) {
        pickupTimeSelect.value = "";
      } else if (completionTimeValue > pickupTimeSelect.value && pickupTimeSelect.value != "") {
        pickupTimeSelect.value = "";
      }
    } else {
      for (const option of pickupTimeSelect.options) {
        option.disabled = false;
      }
    }
  }

  toggleTimeOptions() {
    const completionTimeValue = this.completionTimeOfDayTarget.value;
    const pickupTimeOptions = this.pickupTimeOfDayTarget.options;

    for (const option of pickupTimeOptions) {
      option.disabled = !(option.value >= completionTimeValue);
    }
  }

  checkAndClearCompletionOptions() {
    if (this.hasBookingPreferences && this.jobWillBeCompletedOnTarget.value == "" && !this.rejectedDueToUnverifiedNoShow && !this.isAdmin) {
      this.completionTimeOfDayTarget.innerHTML = "";
    }
  }

  selectBookingPreference(evt) {
    const selectedPreferenceButton = evt.target;
    const selectedPreferenceId = selectedPreferenceButton.dataset.bookingPreferenceId;
    const selectedBookingPreferenceContainer = document.getElementById(`preference-container-${selectedPreferenceId}`);
    const intervalFrom = selectedPreferenceButton.dataset.intervalFrom;
    const intervalTo = selectedPreferenceButton.dataset.intervalTo;
    const date = selectedBookingPreferenceContainer.dataset.date;
    const time = selectedBookingPreferenceContainer.dataset.time;

    selectedPreferenceButton.innerText = selectedPreferenceButton.dataset.selectedText;
    selectedPreferenceButton.classList.add('btn-success');
    selectedPreferenceButton.classList.remove('btn-secondary');
    selectedPreferenceButton.disabled = true;

    document.querySelectorAll('.choose-time-button').forEach((button) => {
      if (button.dataset.bookingPreferenceId != selectedPreferenceId) {
        button.innerText = button.dataset.unselectedText;
        button.classList.add('btn-secondary');
        button.classList.remove('btn-success');
        button.disabled = false;
      }
    });

    this.jobWillBeCompletedOnTarget.value = date;
    this.carPickupAtTarget.value = date;

    if (this.isAdmin) {
      this.completionTimeOfDayTarget.value = time;
    } else if (intervalFrom && intervalTo) {
      this.completionTimeOfDayTarget.classList.remove('bg-gray-100', 'cursor-not-allowed')
      this.addIntervalOptions(intervalFrom, intervalTo);
      this.completionTimeOfDayTarget.value = time;
    } else {
      this.completionTimeOfDayTarget.innerHTML = "";

      const option = new Option(selectedBookingPreferenceContainer.dataset.time);
      this.completionTimeOfDayTarget.add(option);
      option.selected = true;
      this.completionTimeOfDayTarget.classList.add('bg-gray-100', 'cursor-not-allowed')
    }

    this.synchronizeTimeOptions(true);
  }

  handleUnreachableCarOwner() {
    document.querySelector('.close-book-time-modal-button').click();
    document.querySelector('.unreachable-car-owner-button').click();
  }

  handleSuggestDates() {
    document.querySelector('.close-book-time-modal-button').click();
    document.querySelector('.suggest-dates-button').click();
  }

  confirmTime() {
    const hasPickupTime = Boolean(this.pickupTimeOfDayTarget.value);

    this.togglePickupTimeRequiredMessage(hasPickupTime);

    if (this.hasBookingPreferences) {
      const suggestedDateRequiredMessage = document.getElementById('suggestedDateRequiredMessage');
      const hasJobCompletionDate = Boolean(this.jobWillBeCompletedOnTarget.value);

      if (suggestedDateRequiredMessage) {
        suggestedDateRequiredMessage.classList[hasJobCompletionDate ? 'add' : 'remove']('hidden');
      }

      if (hasPickupTime && hasJobCompletionDate) {
        this.toggleConfirmDateButton(true);
      }
    } else {
      this.toggleConfirmDateButton(hasPickupTime);
    }
  }

  togglePickupTimeRequiredMessage(shouldHide) {
    document.querySelectorAll('.pickupTimeRequiredMessage').forEach((element) => {
      element.classList[shouldHide ? 'add' : 'remove']('hidden');
    });
  }

  toggleConfirmDateButton(hasValue) {
    const action = hasValue ? 'add' : 'remove';
    const suggestDatesButton = document.getElementById('suggestDatesButton');

    if (suggestDatesButton) {
      suggestDatesButton.classList[action]('hidden');
    }
    this.confirmDateButtonTarget.classList[action]('hidden');
    this.submitDateButtonTarget.classList[hasValue ? 'remove' : 'add']('hidden');
  }

  addIntervalOptions(intervalFrom, intervalTo) {
    this.completionTimeOfDayTarget.innerHTML = "";

    const intervalFromTotalMinutes = timeToMinutes(intervalFrom);
    const intervalToTotalMinutes = timeToMinutes(intervalTo);
    const amountOfTimeIntervalOptions = (intervalToTotalMinutes - intervalFromTotalMinutes) / 15;

    const minutesArray = [];

    // Create the options in minutes
    for (let i = 0; i <= amountOfTimeIntervalOptions; i++) {
      const minutes = intervalFromTotalMinutes + i * 15;
      minutesArray.push(minutes);
    }

    const timeArray = minutesArray.map(minutesToTime);

    // Add new options in time
    timeArray.forEach(time => {
      const option = new Option(time, time);
      this.completionTimeOfDayTarget.add(option);
   });

    function timeToMinutes(timeString) {
      const [hours, minutes] = timeString.split(":").map(Number);
      return hours * 60 + minutes;
    }

    function minutesToTime(minutes) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
    }
  }
}
