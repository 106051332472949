import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['slider', 'increase', 'decrease'];

  connect(){
    let value = this.sliderTarget.value - 100
    value = Math.floor(value)

    if (value > 0) {
      this.increaseTarget.innerHTML = value
    } else if (value < 100) {
      value = Math.abs(value)
      value = Math.floor(value)
      this.decreaseTarget.innerHTML = value;
    }
  }

  rangeSlide(event) {
    this.sliderTarget.value = event.target.value;

    let value = event.target.value - 100;

    if (value > 0) {
      value = Math.floor(value)
      this.increaseTarget.closest('div').classList.remove('hidden');
      this.decreaseTarget.closest('div').classList.add('hidden');
      this.increaseTarget.innerHTML = value;
    } else if (value == 0) {
      this.decreaseTarget.closest('div').classList.add('hidden');
      this.increaseTarget.closest('div').classList.add('hidden');
    } else if (value < 100) {
      value = Math.abs(value)
      value = Math.floor(value)
      this.increaseTarget.closest('div').classList.add('hidden');
      this.decreaseTarget.closest('div').classList.remove('hidden');
      this.decreaseTarget.innerHTML = value;
    }
  }

  increase(){
    let value = Math.min(parseInt(this.sliderTarget.value) + 1, 200);

    this.sliderTarget.value = value;
    this.rangeSlide({ target: { value } });
  }

  decrease(){
    let value = Math.max(parseInt(this.sliderTarget.value) - 1, 0);
    this.sliderTarget.value = value;
    this.rangeSlide({ target: { value } });
  }

  reset(){
    let value = 100;
    this.sliderTarget.value = value;
    this.rangeSlide({ target: { value } });
  }
}
