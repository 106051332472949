import "@hotwired/turbo-rails";
import Cookies from 'js-cookie';

import { Application } from "@hotwired/stimulus";

import ModalController from "../shared/modal_controller";
import DirectAgreementModalController from "./jobs/direct_agreement_modal_controller";
import DropdownController from "../shared/dropdown_controller";
import ToggleController from "../shared/toggle_controller";
import ImageUploadController from "../shared/image_upload_controller";
import TabsController from "../shared/tabs_controller";
import TimeoutController from "../shared/timeout_controller";
import TooltipController from "../shared/tooltip_controller";
import PopperController from "../shared/popper_controller";
import AutoSubmitController from "../shared/auto_submit_controller";
import MergeWithParamsController from "../shared/merge_with_params_controller";
import NavigationController from "../shared/navigation_controller";

import CountdownController from "../shared/countdown_controller";
import DisableFormFieldsController from "../shared/disable_form_fields_controller";
import NegativeValueValidationController from "../shared/negative_value_validation_controller";

import JobsAutoexpertenController from "./jobs/autoexperten_controller";
import JobsBookTimeController from "./jobs/book_time_controller";
import JobsBookTimeDinitrolController from "./jobs/book_time_dinitrol_controller";

import JobCompletionController from "./jobs/completion_controller";

import JobDetailRequestsController from "./jobs/detail_request_controller";
import JobOfferController from "./jobs/offer_controller";

import JobSuggestDatesController from "./jobs/suggest_dates_controller";
import JobTemplateController from "./jobs/template_controller";

import NextAvailableTimeMainController from "./next_available_time/main_controller";
import NextAvailableTimeRadioSelectedLabelController from "./next_available_time/radio_selected_label_controller";

import MessagesController from "./controllers/messages_controller";
import RepairTimeSliderController from "./controllers/repair_time_slider_controller";
import JobTypesController from "./controllers/job_types_controller";
import UncheckConfigCheckboxesController from "./controllers/uncheck_config_checkboxes_controller";

import ProductPackagesApplyVatController from "./product_packages/apply_vat_controller";
import ProductPackagesScrollDescriptionController from "./product_packages/scroll_description_controller";

import StatisticsActivitiesController from "./statistics/activities_controller";
import ReviewsDashboardController from "./reviews/dashboard_controller";

import SearchFieldController from "./controllers/search_field_controller";
import MultiFormSubmitController from "./controllers/multi_form_submit_controller";
import SignOutController from "./controllers/sign_out_controller";

import '../car_owners/icons';
import '../../misc/datepickers';

require('rails-ujs').start();

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus   = application;

application.register("modal", ModalController);
application.register("direct-agreement-modal", DirectAgreementModalController);
application.register("dropdown", DropdownController);
application.register("toggle", ToggleController);
application.register("image-upload", ImageUploadController);
application.register("tabs", TabsController);
application.register("timeout", TimeoutController);
application.register("tooltip", TooltipController);
application.register("popper", PopperController);
application.register("auto-submit", AutoSubmitController);
application.register("merge-with-params", MergeWithParamsController);
application.register("navigation", NavigationController);

application.register("countdown", CountdownController);
application.register("disable-form-fields", DisableFormFieldsController);
application.register("negative-value-validation", NegativeValueValidationController);

application.register("job-autoexperten", JobsAutoexpertenController);
application.register("job-book-time", JobsBookTimeController);
application.register("job-book-time-dinitrol", JobsBookTimeDinitrolController);

application.register("job-completion", JobCompletionController);

application.register("job-detail-requests", JobDetailRequestsController);
application.register("job-offer", JobOfferController);

application.register("job-suggest-dates", JobSuggestDatesController);
application.register("job-template", JobTemplateController);

application.register("next-available-time-main", NextAvailableTimeMainController);
application.register("next-available-time-radio-selected-label", NextAvailableTimeRadioSelectedLabelController);

application.register("messages", MessagesController);
application.register("repair-time-slider", RepairTimeSliderController);
application.register("job-types", JobTypesController);
application.register("uncheck-config-checkboxes", UncheckConfigCheckboxesController);

application.register("product-packages-apply-vat", ProductPackagesApplyVatController);
application.register("product-packages-scroll-description", ProductPackagesScrollDescriptionController);

application.register("statistics-activities", StatisticsActivitiesController);
application.register("reviews-dashboard", ReviewsDashboardController);

application.register("search-field", SearchFieldController);
application.register("multi-form-submit", MultiFormSubmitController);
application.register("sign-out", SignOutController);

const token = Cookies.get("authorization-token");
if (localStorage.getItem('token') != token) {
  ['TEMPLATES', 'SETTINGS', 'LOGO', 'DIGEST_OPTIONS', 'CHAINS', 'AFFILIATIONS'].forEach(key => {
    let storageKey = 'STORED_DATA_FOR_'.concat(key);
    localStorage.removeItem(storageKey);
  });

  localStorage.removeItem('user');

  localStorage.setItem('token', token);
}

document.addEventListener('turbo:load', () => {
  const shouldOpenNextAvailableTimeModalElement = document.querySelector('meta[name="should_open_next_available_time_modal"]');
  const shouldOpenNextAvailableTimeModal = shouldOpenNextAvailableTimeModalElement && shouldOpenNextAvailableTimeModalElement.content === '1';

  if (shouldOpenNextAvailableTimeModal) {
    setTimeout(function() {
      const editAvailableTimesButton = document.querySelector('.edit-next-available-times-button');
      const editFreeTimesButton = document.querySelector('.edit-next-free-times-button');

      if (editFreeTimesButton)
        editFreeTimesButton.click();
      if (editAvailableTimesButton)
        editAvailableTimesButton.click();
    }, 200);
  }
});
