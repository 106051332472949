import { Controller } from "@hotwired/stimulus"

export default class AutoSubmitController extends Controller {
  connect() {
    this.element.addEventListener("change", () => this.addSubmitButtonClickItAndRemove())
  }

  // Hack to get around form.submit() doesn't call submit event listeners unless submitted through a click on a button
  addSubmitButtonClickItAndRemove() {
    const form = this.element.form
    const fakeSubmitButton = document.createElement("input")

    fakeSubmitButton.type = "submit"
    fakeSubmitButton.value = "FAKE SUBMIT"
    form.appendChild(fakeSubmitButton)
    fakeSubmitButton.click()
    form.removeChild(fakeSubmitButton)

    if (this.element.dataset.flash) {
      const element = document.getElementById('flash-success');
      const textContainer = element.querySelector('.flash-text');

      this.application.getControllerForElementAndIdentifier(element, 'timeout').flash();
      textContainer.innerHTML = this.element.dataset.flash;
    }
  }
}
