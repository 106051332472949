import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "flashable" ]

  connect () {
    const textContainer = this.flashableTarget.querySelector('.flash-text');

    if (textContainer.innerHTML)
      this.flash();
  }

  flash () {
    this.flashableTarget.classList.toggle('hidden');

    setTimeout(() => this.flashableTarget.classList.toggle('hidden'), 8000);
  }
}
