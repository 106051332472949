import { Controller } from "@hotwired/stimulus";

export default class ReviewsDashboardController extends Controller {
  connect() {
    const Highcharts = require('highcharts');

    const dataContainer = document.getElementById('chart-data-container');
    const chart = JSON.parse(dataContainer.dataset.chart);

    Highcharts.chart('chart-container-score-pie', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 180,
        width: 180
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          size: 150,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: null,
        colorByPoint: true,
        data: chart
      }]
    });
  }
}