import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formField", "errorMessage"];

  connect() {
    this.formFieldTargets.forEach(field => {
      field.addEventListener('input', this.validateField.bind(this));
    });
  }

  validateField(event) {
    const field = event.target;
    const index = this.formFieldTargets.indexOf(field);
    const value = parseFloat(field.value);

    if (value < 0) {
      this.errorMessageTargets[index].classList.remove("hidden");
    } else {
      this.errorMessageTargets[index].classList.add("hidden");
    }
  }
}
