import { Controller } from "@hotwired/stimulus"

export default class GeneratorNextAvailableTimeRadioSelectedLabelController extends Controller {
  static targets = ['option']

  connect() {}

  selectOption(evt) {
    this.optionTargets.forEach(option => {
      const labelElm = option.querySelector('.selected-label');
      labelElm.classList.add('hidden');
    });

    const selectedLabelElm = evt.target.parentNode.querySelector('.selected-label');
    selectedLabelElm.classList.remove('hidden');
  }
}
