import { Controller } from "@hotwired/stimulus"

export default class GeneratorProductPackagesApplyVatController extends Controller {
  static targets = ['priceFieldExcludingVat', 'priceFieldIncludingVat']

  connect() {
    this.priceChanged();
  }

  priceChanged() {
    let vatFactor = Number(this.data.get('vat-factor'));
    let priceExcludingVAT = Number(this.priceFieldExcludingVatTarget.value);
    let priceIncludingVAT = priceExcludingVAT * (1 + vatFactor);
    this.priceFieldIncludingVatTarget.value = priceIncludingVAT.toFixed(2);
  }
}
