import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['toggleable'];

  showInfo() {
    const jobId = this.data.get('job-id');
    const offerId = this.data.get('offer-id');
    const detailKind = this.data.get('detail-kind');
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch('/job_detail_requests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({
        job_id: jobId,
        offer_id: offerId,
        detail_kind: detailKind
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'redirect') {
        window.location.href = data.url;
      } else if (data.status === 'success') {
        this.toggleableTargets.forEach(target => {
          target.remove();

          const detailDiv = document.createElement('div');
          detailDiv.innerHTML = data.detail;

          this.element.appendChild(detailDiv);
        });
      }
    });
  }
}
