import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['tab', 'panel', 'initial'];

  initialize() {
    const initialTab = this.data.get('initial') || window.location.hash || this.tabTargets[0].getAttribute('href');

    this.setTab(initialTab);
  }

  change(event) {
    event.preventDefault();

    this.index = this.tabTargets.indexOf(event.currentTarget);
  }

  setTab(identifier) {
    this.index = this.tabTargets.findIndex(element => element.getAttribute('href') === identifier);
    this.showTab();
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index];
      tab.classList.toggle('active', index === this.index);
      panel.classList.toggle('hidden', index !== this.index);
    });
  }

  get index() {
    return parseInt(this.data.get('index') || 0);
  }

  set index(value) {
    this.data.set('index', value);
    this.showTab();
  }

  previous() {
    const currentTab = this.index
    const previousTabIndex = currentTab - 1;
    const previousTab = this.tabTargets[previousTabIndex];

    previousTab.click();
  }

  next() {
    const currentTab = this.index
    const nextTabIndex = parseInt(currentTab) + 1;
    const nextTab = this.tabTargets[nextTabIndex];

    nextTab.click();
  }
}
