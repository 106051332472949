import { Datepicker } from 'vanillajs-datepicker';

document.addEventListener("turbo:load", (event) => {
  const datepickerElements = document.querySelectorAll('.datepicker-input');

  datepickerElements.forEach(datepickerElement => {
    let minDate = null;
    if (datepickerElement.dataset.datepickerMinDate) {
      if(datepickerElement.dataset.datepickerMinDate == 'today') {
        minDate = new Date();
      } else {
        minDate = new Date(datepickerElement.dataset.datepickerMinDate);
      }
    }

    let datesDisabled = [];
    if (datepickerElement.dataset.datepickerDatesDisabled) {
      datesDisabled = JSON.parse(datepickerElement.dataset.datepickerDatesDisabled);
    }

    const datepicker = new Datepicker(datepickerElement, {
      autohide: true,
      format: 'dd/mm/yyyy',
      minDate: minDate,
      datesDisabled: datesDisabled,
      weekStart: 1
    });

    datepickerElement.addEventListener('changeDate', evt => {
      var event = new Event('change');
      datepickerElement.dispatchEvent(event);
    });
  });
});
