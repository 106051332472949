import Cookies from "js-cookie"
import { Controller } from "@hotwired/stimulus"

export default class SignOutController extends Controller {
  clickSignOut(e) {
    e.preventDefault()
    this.signOut()
  }

  signOut() {
    Cookies.set("authorization-token", "")
    localStorage.removeItem('token')
    location.reload()
  }
}
