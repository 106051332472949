import { Controller } from "@hotwired/stimulus";

const TIME_LEFT_KEY = '{{timeLeft}}';
let countdownInterval = 0;

class CountdownController extends Controller {
  static targets = ['countdown'];

  connect() {
    this.setCountdown();
    countdownInterval = setInterval(() => {
      this.setCountdown();
    }, 1000);
  }

  setCountdown() {
    const expirationText = this.data.get('expirationText');
    const total = this.data.get('expiration') - new Date().getTime();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(total / (1000 * 60 * 60));
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    const countdownColor = hours > 48 ? 'text-emerald-600' : 'text-red-600';

    if (total <= 0) {
      clearInterval(countdownInterval);

      if (this.data.get('colorSwitch') === 'true') {
        return;
      }

      // If someone sits around and wait for the offer to expire, we remove it to prevent it goes negative
      this.countdownTarget.remove();
    } else {
      if (days === 0) {
        const timeLeft =
          this.data.get('colorSwitch') === 'true'
            ? `<span class="${countdownColor}">${this.localeTimeLeftHours(
                hours,
                minutes,
                seconds
              )}</span>`
            : this.localeTimeLeftHours(hours, minutes, seconds);
        this.countdownTarget.innerHTML = expirationText.replace(TIME_LEFT_KEY, timeLeft);
      } else {
        const timeLeft =
          this.data.get('colorSwitch') === 'true'
            ? `<span class="${countdownColor}">${this.localeTimeLeftDays(
                days,
                hours,
                minutes
              )}</span>`
            : this.localeTimeLeftDays(days, hours, minutes);
        this.countdownTarget.innerHTML = expirationText.replace(TIME_LEFT_KEY, timeLeft);
      }
    }
  }

  pad(number) {
    return number < 10 ? `0${number}` : number;
  }

  localeTimeLeftDays(days, hours, minutes) {
    const remainderHours = hours % 24;
    switch (this.data.get('locale')) {
      case 'da':
        return `${days}d ${this.pad(remainderHours)}t ${this.pad(minutes)}m`;
      case 'sv':
        return `${days}d ${this.pad(remainderHours)}t ${this.pad(minutes)}m`;
      case 'en':
        return `${days}d ${this.pad(remainderHours)}h ${this.pad(minutes)}m`;
      case 'fr':
        return `${days}j ${this.pad(remainderHours)}h ${this.pad(minutes)}m`;
      case 'de':
        return `${days}t ${this.pad(remainderHours)}h ${this.pad(minutes)}m`;
      default:
        break;
    }
  }

  localeTimeLeftHours(hours, minutes, seconds) {
    switch (this.data.get('locale')) {
      case 'da':
        return `${this.pad(hours)}t ${this.pad(minutes)}m ${this.pad(seconds)}s`;
      case 'sv':
        return `${this.pad(hours)}t ${this.pad(minutes)}m ${this.pad(seconds)}s`;
      case 'en':
        return `${this.pad(hours)}h ${this.pad(minutes)}m ${this.pad(seconds)}s`;
      case 'fr':
        return `${this.pad(hours)}h ${this.pad(minutes)}m ${this.pad(seconds)}s`;
      case 'de':
        return `${this.pad(hours)}h ${this.pad(minutes)}m ${this.pad(seconds)}s`;
      default:
        break;
    }
  }
}

export default CountdownController;
