import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['fuelTypeCheckbox', 'autobidCheckbox', 'fuelTypeCheckboxText', 'autobidCheckboxText'];

  handleFuelTypeCheckboxChange(event) {
    const allUnchecked = this.fuelTypeCheckboxTargets.every(checkbox => !checkbox.checked);

    if (allUnchecked) {
      this.uncheckAndDisableAutobidCheckbox();
      this.autobidCheckboxTextTarget.classList.add('text-gray-400');
    } else if (event.target.checked) {
      this.autobidCheckboxTarget.disabled = false;
      this.autobidCheckboxTextTarget.classList.remove('text-gray-400');
    }
  }

  handlejobTaskTypeConfigCheckboxChange(event) {
    const jobTypeConfigCheckbox = event.target

    this.fuelTypeCheckboxTextTargets.forEach(span => {
      span.classList.toggle('text-gray-400');
    });

    if (jobTypeConfigCheckbox.checked) {
      this.enableFuelTypeCheckboxes();
    } else if (!jobTypeConfigCheckbox.checked) {
      this.uncheckAndDisableAutobidCheckbox();
      this.uncheckAndDisablefuelTypeCheckboxes();

      this.autobidCheckboxTextTarget.classList.add('text-gray-400');
    }
  }

  enableFuelTypeCheckboxes() {
    this.fuelTypeCheckboxTargets.forEach(fuelTypeCheckbox => {
      fuelTypeCheckbox.disabled = false;
    });
  }

  uncheckAndDisableAutobidCheckbox() {
    this.autobidCheckboxTarget.checked = false;
    this.autobidCheckboxTarget.disabled = true;
  }

  uncheckAndDisablefuelTypeCheckboxes() {
    this.fuelTypeCheckboxTargets.forEach(fuelTypeCheckbox => {
      fuelTypeCheckbox.checked = false;
      fuelTypeCheckbox.disabled = true;
    });
  }
}
