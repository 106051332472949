import { Controller } from "@hotwired/stimulus"

export default class GeneratorProductPackagesApplyVatController extends Controller {
  static targets = ['descriptionContainer', 'scrollButton']

  connect() {
    if(this.descriptionContainerTarget.scrollHeight <= this.descriptionContainerTarget.clientHeight) {
      this.scrollButtonTarget.hidden = true;
    }
  }

  scrollDown() {
    if (this.descriptionContainerTarget.scrollTop + 10 > this.descriptionContainerTarget.scrollHeight) {
      this.descriptionContainerTarget.scrollTop = this.descriptionContainerTarget.scrollHeight;
    } else {
      this.descriptionContainerTarget.scrollTop = this.descriptionContainerTarget.scrollTop + 10;
    }
  }
}
