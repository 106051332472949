import { Controller } from "@hotwired/stimulus"

export default class SearchFieldController extends Controller {
  static targets = ["searchTextField"]

  submit(e) {
    e.preventDefault()

    const searchValue = this.searchTextFieldTarget.value
    const newUrl = `/searches?search=${encodeURI(searchValue)}`

    location.href = newUrl
  }
}
