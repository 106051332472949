import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dateTimePickerContainer', 'datePicker', 'timePicker', 'resetButton', 'submitButton', 'confirmButton', 'selectThreeDifferentDatesError', 'emptyDateError', 'emptyTimeError', 'sameDateError', 'readOnlyDate', 'readOnlyTime'];

  validateFields() {
    let hasEmptyDatePicker = false;
    let hasEmptyTimePicker = false;
    let dates = [];

    this.dateTimePickerContainerTargets.forEach((container, index) => {
      const datePicker = this.datePickerTargets[index];
      const timePicker = this.timePickerTargets[index];
      const emptyDateError = this.emptyDateErrorTargets[index];
      const emptyTimeError = this.emptyTimeErrorTargets[index];
      const sameDateError = this.sameDateErrorTargets[index];

      if (!datePicker.value) {
        hasEmptyDatePicker = true;
        emptyDateError.classList.remove('hidden');
      } else {
        emptyDateError.classList.add('hidden');
        dates.push({date: datePicker.value, container: container});
      }

      if (!timePicker.value) {
        hasEmptyTimePicker = true;
        emptyTimeError.classList.remove('hidden');
      } else {
        emptyTimeError.classList.add('hidden');
      }

      sameDateError.classList.add('hidden');
    });

    this.handleSameDateError(dates);

    let threeUniqueDatesSelected = new Set(dates.map(item => item.date)).size === 3;

    if (threeUniqueDatesSelected) {
      this.selectThreeDifferentDatesErrorTarget.classList.add('hidden');
    } else {
      this.selectThreeDifferentDatesErrorTarget.classList.remove('hidden');
    }

    if (!hasEmptyDatePicker && !hasEmptyTimePicker && threeUniqueDatesSelected) {
      this.submitButtonTarget.classList.add('hidden');
      this.confirmButtonTarget.classList.remove('hidden');
      this.resetButtonTarget.classList.remove('hidden');
      this.toggleDateTimePickers(true);
    }
  }

  resetFields() {
    this.toggleDateTimePickers(false);
    this.resetButtonTarget.classList.add('hidden');
    this.submitButtonTarget.classList.remove('hidden');
    this.confirmButtonTarget.classList.add('hidden');
  }

  toggleDateTimePickers(disabled) {
    this.dateTimePickerContainerTargets.forEach((container, index) => {
      const datePicker = this.datePickerTargets[index];
      const timePicker = this.timePickerTargets[index];
      const readOnlyDate = this.readOnlyDateTargets[index];
      const readOnlyTime = this.readOnlyTimeTargets[index];

      datePicker.classList.toggle("hidden", disabled);
      timePicker.classList.toggle("hidden", disabled);

      readOnlyDate.value = datePicker.value;
      readOnlyDate.classList.remove('hidden');

      readOnlyTime.value = timePicker.value;
      readOnlyTime.classList.remove("hidden");

      if (!disabled) {
        datePicker.value = '';
        timePicker.value = '';
        readOnlyDate.classList.add('hidden');
        readOnlyTime.classList.add("hidden");
      }
    });
  }

  handleSameDateError(dates) {
    let dateSet = new Set();
    dates.forEach((dateContainerHash) => {
      if (dateSet.has(dateContainerHash.date)) {
        let sameDateErrorElement = dateContainerHash.container.querySelector('.same-date-error');
        sameDateErrorElement.classList.remove('hidden');
      } else {
        dateSet.add(dateContainerHash.date);
      }
    });
  }
}
