import { config, library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faBars,
  faBell,
  faCalendar,
  faCamera,
  faCaretDown,
  faCar,
  faCarSide,
  faCheck,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCog,
  faComment,
  faComments,
  faEye,
  faExclamationCircle,
  faLocationArrow,
  faFilter,
  faInfoCircle,
  faLink,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faUser,
  faUsers,
  faPhone,
  faPhoneAlt,
  faPlus,
  faRandom,
  faReceipt,
  faRss,
  faSearch,
  faSignOutAlt,
  faSpinner,
  faStar,
  faTimes,
  faTrashAlt,
  faQuestionCircle,
  faThLarge,
  faWrench,
  faUsersCog,
	faCircle,
  faAngleUp,
  faPencilAlt,
  faTrash,
  faPaperPlane,
  faHeart,
  faCommentsDollar,
  faLock,
  faPlusCircle,
  faMinusCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
  faCalendarAlt,
  faClock,
  faCreditCard,
  faEdit,
  faFileAlt,
  faMoneyBillAlt,
  faUserCircle
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faArchive,
  faBars,
  faBell,
  faCalendar,
  faCamera,
  faCalendarAlt,
  faCaretDown,
  faCar,
  faCarSide,
  faCheck,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCog,
  faComment,
  faComments,
  faCreditCard,
  faEdit,
  faEye,
  faExclamationCircle,
  faFacebookF,
  faFileAlt,
  faFilter,
  faInfoCircle,
  faLocationArrow,
  faLink,
  faLinkedinIn,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faMoneyBillAlt,
  faPlus,
  faPhone,
  faPhoneAlt,
  faRandom,
  faReceipt,
  faRss,
  faSearch,
  faSignOutAlt,
  faSpinner,
  faStar,
  faTimes,
  faThLarge,
  faTrashAlt,
  faTwitter,
  faUser,
  faUsers,
  faUserCircle,
  faYoutube,
  faQuestionCircle,
  faWrench,
  faUsersCog,
	faCircle,
  faAngleUp,
  faPencilAlt,
  faTrash,
  faPaperPlane,
  faHeart,
  faCommentsDollar,
  faLock,
  faPlusCircle,
  faMinusCircle
);

dom.watch();
