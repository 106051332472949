import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "showMore", "section"];

  connect() {
    this.checkboxTarget.addEventListener('change', this.showSection.bind(this));
    this.showMoreTarget.addEventListener('click', this.showSection.bind(this));
  }

  showSection() {
    this.sectionTargets.forEach(section => section.classList.remove('hidden'));
    this.showMoreTarget.classList.add('hidden');
  }
}
